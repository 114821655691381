.App {
  text-align: justify;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 0;
  position: relative;
  overflow: hidden;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: white;
}

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */





/* ChatStyles */
.page-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0;
}

.chat-sidebar {
  width: 250px;
  background: #333;
  color: white;
  overflow-y: auto;
}

.new-chat {
  padding: 0;
  margin: 0;
}

.new-chat-btn {
  background: #333;
  color: white;
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 0.1rem outset;
  cursor: pointer;
  width: 60%;
}

.chat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-item {
  border: 0 solid #d9d9e3;
  padding: 10px;
  border-bottom: 1px solid #666;
  cursor: pointer;
  --tw-text-opacity: 1;
  background-color: rgb(32 33 35 / var(--tw-text-opacity));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-item:hover {
  background: #444;
}

.chat-item.selected {
  background-color: #777;
}



.chat-text {
  flex-grow: 1;
}

.icon-fa {
  cursor: pointer;
  padding: 0 2px;
}

.icon-fa.delete:hover {
  color: black; /* 鼠标悬停时的颜色 */
}

.icon-fa.edit:hover {
  color: black; /* 鼠标悬停时的颜色 */
}













/* .chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
} */

.configuration-container {
  width: 100%;
  /* background: #ddd; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.config-btn {
  padding: 5px 10px;
  cursor: pointer;
}
.chat-area {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}
.chat-input-area {
  display: flex;
  padding: 10px;
  background: #ddd;
  align-items: center;
}
.chat-input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
}
.send-btn {
  padding: 10px 20px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .sidebar {
    width: 100px;
  }
  .chat-item {
    padding: 5px;
    font-size: 0.8em;
  }
}