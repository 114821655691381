.render-content a,.render-content{
    color: #000000;
}
.render-content a {
    border-bottom:1px solid #000000;
    text-decoration: none;
}
.render-content code {
    color: #ffffff;
    background-color: #000000d7;
    border-radius: 10px;
    font-size: 16px;
    display: block;
    white-space: pre;
    padding: 15px;
    margin: 15px 0;
    word-break: break-all;
    overflow: auto;
}
.render-content a:hover {
    color:#efa823;
    border-color: #efa823;
}