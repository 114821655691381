/* Message.css - Styling for the Message component */
.message {
  display: flex;
  /* margin-bottom: 10px; */
}
  
.left-align {
  /* justify-content: flex-start; */
  flex-direction: row;
}

.right-align {
  /* justify-content: flex-end; */
  flex-direction: row-reverse;
  /* flex-direction: row; */
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px; /* Spacing between avatar and message */
}

.message-box {
  /* max-width: 80%; */
  border-radius: 10px;
  position: relative;
  /* background-color: #f0f0f0; */
  justify-content: center;
  align-items: center;
}

.message-info {
  padding: 10px 10px 0 10px;
}

.message-box-info {
  font-size: 0.5em;
  color: #333;
  display: flex;
  align-Items: center
}

.copy-icon, .icon-copied {
  font-size: 20px; /* 设置图标大小 */
  color: #ccc; /* 默认颜色 */
  cursor: pointer; /* 鼠标悬停时显示指针光标 */
  transition: color 0.2s; /* 平滑颜色过渡 */
  margin: 10px;
}

.copy-icon:hover {
  color: black; /* 鼠标悬停时的颜色 */
}


.message-sender {
  font-size: 0.5em; /* Smaller font size for the sender's name */
  color: #333;
  white-space: nowrap; /* Prevent the name from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis to overflowed text */
  max-width: 80px; /* Set a max-width for the sender's name */
  margin: 0 10px;
}

.message-text {
  font-size: 1em;
  word-wrap: break-word;
  background-color: #f0f0f0;
  max-width: 80%;
  padding: 1px 10px 1px 10px;
  border-radius: 10px;
  text-align: start;
}

.sender-name {
  font-weight: bold;
  margin-bottom: 5px;
  color: #000000;
}

  

.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 10px; */
  /* padding: 20px; */
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  overflow: hidden; /* Hide the overflow */
  justify-content: center;
  text-align: center;
}
  
.chat-box {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  flex-grow: 1; /* Grow to use available space */
  background-color: #ffffff; /* Chat background color */
  width: 60%;
  margin: 0 auto; /* 居中 */
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center; /* This ensures vertical alignment */
  width: 100%;
  margin: 0 auto; /* 居中 */
}

.textarea {
  width: 50%; /* Adjust to account for button width */
  height: 60px; /* Example height, adjust as needed */
  resize: none; /* Optional: prevents resizing */
  margin: 10px 10px;
  border: 1px solid #d0d0d0; 
  border-radius: 10px;
  padding: 10px;
}

.send-button {
  width: 10%; /* Adjust based on actual button content and desired appearance */
  height: 60px; /* Same as textarea for equal height */
  margin-left: 10px; /* Spacing between textarea and button */
  background-color: #4CAF50; 
  color: white;
  border: none;
  border-radius: 10px;
  margin: 10px 10px;
}

.send-button:hover {
  background-color: #45a049; /* Slightly darker green for button hover state */
}

.message-img {
  max-width: 100%; /* Ensure the image is not larger than the message container */
  border-radius: 10px; /* Optional: for styled corners */
  margin: 10px 0; /* Spacing around the image */
}

.change-model {
  padding: 10px;
  margin: 10px;
}

.change-submodel {
  padding: 10px;
  margin: 10px;
}

.change-enter {
  padding: 10px;
  margin: 10px;
}

.loading {
  color: #333;
}

.hidden-chat-btn {
  color: #666;
  margin: auto;
  padding: 10px;
  height: 2em;
}

.hidden-chat-btn:hover {
  color: #111;
  cursor: pointer;
}